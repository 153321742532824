export const environment = {
  production: true,
  apiBase: 'https://api.athsolutions.net/api/',
  socialModule: 'https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.esm.js',
  socialNoModule: 'https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.js',
  socialDashboardBase: 'https://dashboard.socialvenu.com/',
  socialAdminDashToken: '9679ff33-de64-4413-babf-199713751bd9',
  paypalAAC: 'https://www.paypalobjects.com/payouts/js/payouts_aac.js',
  paypalClientId: 'AYgi_vbjk6ePoOmX5BbxQts1H_W-uwh_iyXqNzuBLEsBVrdFN__riMaL6tvTeK802pzT5vx-yqVhnhRw',
  paypalMerchantId: 'LH957BZY22Y9S',
  coalesceBase: 'https://coalesceink.athsolutions.net/',
};
